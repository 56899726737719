import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  pageSize: 10,
  search: "",
  filterStatus: "All",
  filterPriority: "All",
  detailAntrianGroup: {
    id: null,
    open: false,
    title: null,
    data: {
      nota: null,
      antrian: 0,
      size: 0,
      additional: 0,
      status: { warna: "white", progress: 0, status: "" },
    },
  },
  cardTotalAntrian: { id: null, open: false, title: null, data: [] },
  progressGroup: {
    id: null,
    open: false,
    title: null,
    data: {
      nota: null,
      keterangan: null,
      antrian: 0,
      size: 0,
      additional: 0,
      status: { id: null, nama: "", warna: "white" },
      list: [],
    },
  },
  selesaiGroup: {
    id: null,
    open: false,
    title: null,
    data: {
      nota: null,
      keterangan: null,
      antrian: 0,
      size: 0,
      additional: 0,
      list: [],
    },
  },
  printGroup: {
    id: null,
    open: false,
    title: null,
    data: {
      nota: null,
      keterangan: null,
      antrian: 0,
      size: 0,
      additional: 0,
      list: [],
      gambar: null,
    },
  },
  refetch_status: false,
  detailModal: { id: null, open: false, title: null },
};

const DikerjakanGroup = createSlice({
  name: "dikerjakanGroup",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
      state.page = 1;
    },
    SET_SEARCH: (state, action) => {
      state.search = action.payload.search;
      state.page = 1;
    },
    SET_FILTER_STATUS: (state, action) => {
      state.filterStatus = action.payload.filterStatus;
      state.page = 1;
    },
    SET_FILTER_PRIORITY: (state, action) => {
      state.filterPriority = action.payload.filterPriority;
      state.page = 1;
    },
    SET_DETAIL_ANTRIAN_GROUP: (state, action) => {
      state.detailAntrianGroup = action.payload.modal;
    },
    SET_CARD_TOTAL_ANTRIAN: (state, action) => {
      state.cardTotalAntrian = action.payload.modal;
    },
    SET_PROGRESS_GROUP: (state, action) => {
      state.progressGroup = action.payload.modal;
    },
    SET_SELESAI_GROUP: (state, action) => {
      state.selesaiGroup = action.payload.modal;
    },
    SET_PRINT_GROUP: (state, action) => {
      state.printGroup = action.payload.modal;
    },
    SET_REFETCH_STATUS: (state, action) => {
      state.refetch_status = action.payload.refetch_status;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH,
  SET_FILTER_STATUS,
  SET_FILTER_PRIORITY,
  SET_DETAIL_ANTRIAN_GROUP,
  SET_CARD_TOTAL_ANTRIAN,
  SET_PROGRESS_GROUP,
  SET_SELESAI_GROUP,
  SET_PRINT_GROUP,
  SET_REFETCH_STATUS,
  SET_DETAIL_MODAL,
} = DikerjakanGroup.actions;

export default DikerjakanGroup.reducer;
