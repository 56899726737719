import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Tabs,
  Tab,
  AppBar,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Layout from "../layout/Layout";
import { AddButton } from "../components/controls";
import {
  useFormAntrian,
  EventHandlers as EventHandlersAntrianNota,
  useFormAntrianSimple,
} from "../features/antrian/nota/repository";
import {
  Api,
  EventHandlers as EventHandlersGroup,
} from "../features/antrian/group/repository";
import { EventHandlers as EventHandlersAntrianPage } from "../features/antrian/page/repository";
import {
  InputSearch,
  SortingLength,
  TabPanel,
  a11yProps,
} from "../components/common";
import NotaPage from "../features/antrian/nota/NotaPage";
import GroupPage from "../features/antrian/group/GroupPage";

const Antrian = () => {
  const dispatch = useDispatch();
  const formAntrian = useFormAntrian();
  const formAntrianSimple = useFormAntrianSimple();

  const { tab } = useSelector((state) => state.antrian);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const { pageSize, search, filterStatus, filterPriority } = useSelector(
    (state) => state.antrianGroup
  );

  const { data } = Api.read();

  const statusOptions = Array.from(
    new Map(
      data
        ? data.data
            .filter((item) => item.status.id)
            .map((item) => [item.status.id, item.status])
        : []
    ).values()
  );

  // CHANGE TITLE
  useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Antrian`;
      } else {
        document.title = `Antrian`;
      }
    } else {
      document.title = "Antrian";
    }
  }, [setting]);

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {tab !== 1 ? (
          role === "customer_service" ? (
            <Grid
              container
              columnSpacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Grid item>
                <AddButton
                  title="Tambah Antrian"
                  onClick={() => {
                    EventHandlersAntrianNota.handleCreateUpdateModal({
                      dispatch,
                      modal: { id: null, open: true, title: "Tambah Antrian" },
                      clearErrors: formAntrian.clearErrors,
                      reset: formAntrian.reset,
                    });
                  }}
                />
              </Grid>

              <Grid item>
                <AddButton
                  title="Fast Input"
                  onClick={() => {
                    EventHandlersAntrianNota.handleCreateSimpleModal({
                      dispatch,
                      modal: {
                        id: null,
                        open: true,
                        title: "Tambah Antrian Simple",
                      },
                      clearErrors: formAntrianSimple.clearErrors,
                      reset: formAntrianSimple.reset,
                    });
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <div></div>
          )
        ) : (
          <>
            <Grid
              container
              columnSpacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Grid item>
                <InputSearch
                  value={search}
                  handleChange={(search) =>
                    EventHandlersGroup.handleSearch({ dispatch, search })
                  }
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth sx={SelectStyle}>
                  <InputLabel id="demo-simple-select-label">
                    Filter Status
                  </InputLabel>
                  <Select
                    value={filterStatus}
                    label="Filter Status"
                    onChange={(event) =>
                      EventHandlersGroup.handleFilterStatus({
                        dispatch,
                        filterStatus: event.target.value,
                      })
                    }
                    size="small"
                  >
                    <MenuItem value="All">Semua Status</MenuItem>
                    {statusOptions.map((status, index) => (
                      <MenuItem value={status.id} key={index}>
                        {status.status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth sx={SelectStyle}>
                  <InputLabel id="demo-simple-select-label">
                    Filter Priority
                  </InputLabel>
                  <Select
                    value={filterPriority}
                    label="Filter Priority"
                    onChange={(event) =>
                      EventHandlersGroup.handleFilterPriority({
                        dispatch,
                        filterPriority: event.target.value,
                      })
                    }
                    size="small"
                  >
                    <MenuItem value="All">Semua Priority</MenuItem>
                    <MenuItem value={false}>Regular</MenuItem>
                    <MenuItem value={true}>Prioritas</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SortingLength
              value={pageSize}
              handleChange={(pageSize) =>
                EventHandlersGroup.handlePageSize({ dispatch, pageSize })
              }
            />
          </>
        )}
        <AppBar
          position="static"
          elevation={0}
          sx={{ backgroundColor: "#fff", width: "250px" }}
        >
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(_, value) =>
              EventHandlersAntrianPage.handleTab({ dispatch, tab: value })
            }
            centered
          >
            <Tab {...a11yProps(0)} label="BY NOTA" />
            <Tab {...a11yProps(1)} label="BY GROUP" />
          </Tabs>
        </AppBar>
      </Stack>

      {/* Content */}
      <TabPanel value={tab} index={0}>
        <NotaPage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <GroupPage />
      </TabPanel>
    </Layout>
  );
};

const SelectStyle = {
  "& .MuiInputBase-root": {
    color: "#1976d2",
    borderRadius: "5px",
    fontSize: "13px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& .Mui-disabled": {
      borderRadius: "6px",
      backgroundColor: "#ECECEC",
      fontWeight: 700,
    },
    "& .MuiSvgIcon-root": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(25, 118, 210, 0.5)",
    marginTop: "5px",
  },
  "& .MuiInputLabel-shrink": {
    display: "none",
    color: "#1976d2",
    fontWeight: 500,
    top: "-5px",
  },
  "& legend": {
    display: "none",
  },
  "& .Mui-focused + .MuiOutlinedInput-root": {
    "& .MuiInputLabel-shrink": {
      color: "#1976d2",
    },
  },
  "& .Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiFormLabel-root": {
    color: "#1976d2",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 500,
    backgroundColor: "#fff",
    paddingX: "5px",
    position: "absolute",
    top: 23,
    right: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
};

export default Antrian;
