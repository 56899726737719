import React from "react";
import { checkDate, dateFormat } from "../../../../controls";
import TabelAntrianGroup from "../TabelAntrianGroup";
import { ChipItems, NumericText } from "../../../../common";
import { Info as InfoIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const TotalAntrianCard = ({ data, onDetail }) => {
  // column antrian
  const totalAntrian = [
    {
      field: "no",
      headerName: "No",
      flex: 1,
      filterable: false,
      minWidth: 50,
      maxWidth: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const jarak = checkDate(params.row.check_in);
        return (
          <span
            style={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "nota",
      flex: 1,
      minWidth: 100,
      hideable: false,
      headerName: "No. Nota",
      renderCell: (params) => {
        const jarak = checkDate(params.row.check_in);
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
            {jarak.interval >= 2 && (
              <sup
                style={{
                  color: jarak.warna,
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {" "}
                {jarak.interval > 0 && jarak.interval}
              </sup>
            )}
          </span>
        );
      },
    },
    {
      field: "check_in",
      type: "date",
      flex: 1,
      minWidth: 100,
      headerName: "Check-In",
      valueGetter: (value) => new Date(value),
      renderCell: (params) => {
        const jarak = checkDate(params.row.check_in);
        if (params.value instanceof Date && !isNaN(params.value)) {
          return (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                ...(jarak.interval > 2 && {
                  color: jarak.warna,
                  ...(jarak.interval > 4 && { fontWeight: "bold" }),
                }),
              }}
            >
              {dateFormat(params.value)}
            </span>
          );
        }
        return "";
      },
    },
    {
      field: "nama",
      flex: 1,
      sortable: false,
      minWidth: 100,
      headerName: "Leads / Nama",
      renderCell: (params) => {
        const jarak = checkDate(params.row.check_in);
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "order",
      flex: 1,
      type: "number",
      sortable: false,
      minWidth: 50,
      headerName: "Order",
      renderCell: (params) => {
        const jarak = checkDate(params.row.check_in);
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={false}
              suffix={" Pcs"}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Progress",
      minWidth: 110,
      renderHeader: () => <strong>Progress</strong>,
      valueGetter: (value) => value.nama,
      renderCell: (params) => {
        return (
          <ChipItems
            size="small"
            label={params.value}
            backgroundcolor={params.row.status.warna}
            borderradius={"3px"}
            sx={{ height: "20px" }}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      hideable: false,
      renderHeader: () => <InfoIcon />,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                // console.log(params.id);
                onDetail(params.id);
              }}
            >
              <InfoIcon
                sx={{
                  fontSize: "21px",
                  color: "rgba(147, 147, 147, 1)",
                  borderRadius: "50%",
                  "&:hover": {
                    color: "#2FA9EE",
                  },
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const rowsAntrianData = data.map((row, index) => ({
    ...row,
    no: index + 1, // Menambahkan properti id untuk mendapatkan nomor otomatis
  }));

  return (
    <TabelAntrianGroup
      rows={rowsAntrianData}
      column={totalAntrian}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
    />
  );
};

export default TotalAntrianCard;
