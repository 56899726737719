import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    loading: false,
    errorServer: false,
    drawerWidth: 240,
    drawerOpen: true,
    density: "standard",
    pageSizeOptions: [10, 25, 50, 100],
  },

  reducers: {
    updateDrawer: (state, action) => {
      state.drawerOpen = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateErrorServer: (state, action) => {
      state.errorServer = action.payload;
    },
    updateDensity: (state, action) => {
      state.density = action.payload.density;
    },
  },
});

export const { updateDrawer, updateLoading, updateErrorServer, updateDensity } =
  themeSlice.actions;
export default themeSlice.reducer;
