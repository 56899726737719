import React, { useState } from "react";
import QRCode from "react-qr-code";
import md5 from "md5";
import {
  Grid,
  Divider,
  Typography,
  Skeleton,
  Button,
  Tooltip,
} from "@mui/material";
import { Photo as PhotoIcon } from "@mui/icons-material";
import LoadingProgress from "../../../common/LoadingProgress";
import CollapseButton from "../../../common/CollapseButton";
import CheckIn from "../../../../assets/icon/detail/checkin.svg";
import CheckOut from "../../../../assets/icon/detail/checkout.svg";
import CsIn from "../../../../assets/icon/detail/csin.svg";
import CsOut from "../../../../assets/icon/detail/csout.svg";
import { CardOrder, CollapseDetailOrder } from "./DetailAntrian";
import { dateFormatter } from "../../../controls";

const detailItems = [
  { label: "No. Nota", property: "nota" },
  { label: "Nama", property: "nama" },
  { label: "No. HP", property: "nohp" },
  { label: "Jenis Order", property: "jenisorder" },
  { label: "Source Order", property: "sourceorder" },
  { label: "Alamat", property: "alamat" },
  { label: "", property: "alamatDetail" },
  { label: "Desainer", property: "desainer" },
  { label: "Produksi", property: "produksi" },
];

const DetailAntrianLayout = (props) => {
  const { data, isLoading, previewImage } = props;

  const dataDetail = {
    nota: data?.antrian?.nota || "-",
    nama: data?.detail?.nama || "-",
    nohp: data?.detail?.no_hp || "-",
    jenisorder: data?.antrian?.jenis_order?.nama || "-",
    sourceorder: data?.antrian?.source_order?.nama || "-",
    alamat: data?.detail?.wilayah || "-",
    alamatDetail: data?.detail?.alamat || "-",
    progress: data?.status?.nama || "-",
    checkin: data?.progress?.check_in?.tanggal
      ? dateFormatter({
          date: new Date(data?.progress?.check_in?.tanggal),
          withHari: true,
          withMonth: "simple",
          withTimes: true,
          yearLength: "full",
        })
      : "-",
    checkout: data?.progress?.check_out?.tanggal
      ? dateFormatter({
          date: new Date(data?.progress?.check_out?.tanggal),
          withHari: true,
          withMonth: "simple",
          withTimes: true,
          yearLength: "full",
        })
      : "-",
    desainer: data?.progress?.desainer
      ? {
          usercode: data.progress.desainer.usercode,
          nama: data.progress.desainer.nama,
        }
      : "-",
    produksi: data?.progress?.produksi
      ? {
          usercode: data.progress.produksi.usercode,
          nama: data.progress.produksi.nama,
        }
      : "-",
    csIn: data?.progress?.check_in
      ? {
          usercode: data.progress.check_in.usercode,
          nama: data.progress.check_in.nama,
        }
      : "-",
    csOut: data?.progress?.check_out
      ? {
          usercode: data.progress.check_out.usercode,
          nama: data.progress.check_out.nama,
        }
      : "-",
    status: {
      nama: data?.progress?.status?.nama || "-",
      warna: data?.progress?.status?.warna || "#FFF",
      progress: data?.progress?.status?.progress || 0,
    },
    prioritas: data?.antrian?.prioritas || false,
    gambar: data?.detail?.gambar || null,
  };

  // collapse-expand
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <Grid
      container
      marginTop={1}
      sx={{
        justifyContent: "space-between",
        fontSize: "12px",
        color: "#797979",
        fontWeight: "400",
      }}
    >
      <Grid item md={10}>
        {detailItems.map((item, index) => (
          <Grid
            key={index}
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              {item.property === "nota" ? (
                <span style={{ fontWeight: 700, color: "#000000" }}>
                  {item.label}
                </span>
              ) : (
                item.label
              )}
            </Grid>
            <Grid item xs={0.2}>
              {item.property === "alamatDetail" ? null : ":"}
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {isLoading ? (
                <Skeleton />
              ) : item.property === "nota" ? (
                <span style={{ fontWeight: 700, color: "#000000" }}>
                  {dataDetail[item.property]}
                </span>
              ) : item.property === "nama" ? (
                <span>
                  {dataDetail[item.property].length > 15
                    ? `${dataDetail[item.property].slice(0, 15)}...`
                    : dataDetail[item.property]}
                </span>
              ) : item.property === "nohp" ? (
                <span>
                  {dataDetail[item.property].replace(
                    /(\d{4})(\d{4})(\d{4})/,
                    "$1 $2 $3"
                  )}
                </span>
              ) : item.property === "jenisorder" ||
                item.property === "sourceorder" ||
                item.property === "alamat" ? (
                <b>{dataDetail[item.property]}</b>
              ) : item.property === "alamatDetail" ? (
                <span style={{ fontSize: "10px" }}>
                  {dataDetail[item.property]}
                </span>
              ) : item.property === "desainer" ||
                item.property === "produksi" ? (
                dataDetail[item.property].usercode ? (
                  <span style={{ textTransform: "uppercase" }}>
                    {dataDetail[item.property].usercode + " "}
                    <strong>{dataDetail[item.property].nama}</strong>
                  </span>
                ) : (
                  "-"
                )
              ) : (
                dataDetail[item.property]
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        md={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            flexGrow: 1,
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={90} height={90} />
          ) : (
            <QRCode value={md5(dataDetail.nota)} size={100} />
          )}
          <div style={{ marginTop: "0.2rem" }}>
            {dataDetail.prioritas ? (
              <Typography variant="subtitle2" color={"#FF5C00"}>
                Prioritas
              </Typography>
            ) : (
              <Typography variant="subtitle2" color={"#797979"}>
                Reguler
              </Typography>
            )}
          </div>
          <Tooltip
            title={dataDetail.gambar == null ? "No Image" : ""}
            disableHoverListener={dataDetail.gambar != null}
            followCursor
          >
            <span>
              <Button
                variant="contained"
                startIcon={<PhotoIcon />}
                onClick={() =>
                  dataDetail.gambar && previewImage(dataDetail.gambar)
                }
                sx={{
                  marginTop: 1,
                  backgroundColor: "#566a7d",
                  "&:hover": { backgroundColor: "#455665" },
                }}
                disabled={dataDetail.gambar == null}
              >
                Preview
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      {/* loadaing progress */}
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={2}
      >
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="subtitle2" marginRight={0.5}>
            Progress :{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            color={dataDetail.status.warna}
            fontWeight={700}
          >
            {dataDetail.status.nama}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingProgress
            progress={dataDetail.status.progress}
            barWidth={"100%"}
            barcolor={dataDetail.status.warna}
          />
        </Grid>
      </Grid>

      {/* Check In-Out */}
      <Grid container item pt={2} alignItems="center">
        <Grid item xs={2.6}>
          Check In - Out
        </Grid>
        <Grid item xs={0.2}>
          :
        </Grid>
        <Grid container item xs={4.6} alignItems="center">
          <img
            src={CheckIn}
            alt="checkin"
            style={{
              width: "15px",
              height: "15px",
              margin: "0 0.3rem",
            }}
          />
          {dataDetail.checkin}
          {/* <Skeleton variant="rectangular" /> */}
        </Grid>
        <Grid container item xs={4.6} alignItems="center">
          <img
            src={CheckOut}
            alt="checkout"
            style={{
              width: "15px",
              height: "15px",
              margin: "0 0.3rem",
            }}
          />
          {dataDetail.checkout}
          {/* <Skeleton variant="rectangular" /> */}
        </Grid>
      </Grid>

      {/* CS In-Out */}
      <Grid container item pt={1} alignItems="center">
        <Grid item xs={2.6}>
          Customer Service
        </Grid>
        <Grid item xs={0.2}>
          :
        </Grid>
        <Grid container item xs={4.6} alignItems="center">
          <img
            src={CsIn}
            alt="csin"
            style={{
              width: "15px",
              height: "15px",
              margin: "0 0.3rem",
            }}
          />
          {dataDetail.csIn.usercode ? (
            <>
              {dataDetail.csIn.usercode}&nbsp;
              <strong>{dataDetail.csIn.nama}</strong>
            </>
          ) : (
            "-"
          )}
          {/* <Skeleton variant="rectangular" /> */}
        </Grid>
        <Grid container item xs={4.6} alignItems="center">
          <img
            src={CsOut}
            alt="csout"
            style={{
              width: "15px",
              height: "15px",
              margin: "0 0.3rem",
            }}
          />
          {dataDetail.csOut.usercode ? (
            <>
              {dataDetail.csOut.usercode}&nbsp;
              <strong>{dataDetail.csOut.nama}</strong>
            </>
          ) : (
            "-"
          )}
          {/* <Skeleton variant="rectangular" /> */}
        </Grid>
      </Grid>

      <Grid item xs={12} pt={2}>
        <Divider>
          <CollapseButton
            onClick={handleChange}
            checked={checked}
            expandLabel="Order Detail"
            collapseLabel="Order Detail"
          />
        </Divider>
        {/* CollapseDetailOrder */}
        <CollapseDetailOrder
          in={checked}
          dataAntrianSize={data?.antrian_size ?? []}
          keteranganNota={data?.antrian?.keterangan ?? null}
          reject={data?.antrian?.reject ?? null}
        />
      </Grid>

      {/* CardOrder */}
      <Grid container item mt={0.5} spacing={2}>
        <CardOrder
          ekspedisi={data?.antrian?.ekspedisi ?? {}}
          total={data?.total ?? {}}
        />
      </Grid>
    </Grid>
  );
};

export default DetailAntrianLayout;
