import React from "react";
import Layout from "../layout/Layout";
import PencapaianNotaPage from "../features/pencapaian/nota/PencapaianNotaPage";

const Pencapaian = () => {
  return (
    <Layout>
      {/* Content */}
      <div>
        <PencapaianNotaPage />
      </div>
    </Layout>
  );
};

export default Pencapaian;
