import { Config } from ".";
import {
  useCreatingData,
  useDeletingData,
  useFetchingData,
  useUpdatingData,
} from "../../../../hooks";
export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/antrian`,
    });
  };

  static readAntrian = () => {
    return useFetchingData({
      endpoint: `antrian`,
    });
  };

  static detailAntrianPerNota = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static deleteGroup = ({ handleClose }) => {
    return useDeletingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [`${Config.variable.endpoint}/antrian`, `antrian`],
      onSuccess: () => {
        handleClose();
      },
    });
  };

  static updateNota = ({ handleClose }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/antrian`,
      invalidated: [`${Config.variable.endpoint}/antrian`, `antrian`],
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        return;
      },
    });
  };

  static produksiAntrainGroup = ({ handleClose }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/produksi`,
      invalidated: [`${Config.variable.endpoint}/antrian`, `antrian`],
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        return;
      },
    });
  };

  static updateGroup = ({ setProgress, id, handleClose }) => {
    return useCreatingData({
      endpoint: `${Config.variable.endpoint}/${id}`,
      invalidated: [`${Config.variable.endpoint}/antrian`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        return;
      },
    });
  };

  static createGroup = ({ setProgress, handleClose }) => {
    return useCreatingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [`${Config.variable.endpoint}/antrian`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        return;
      },
    });
  };
}
