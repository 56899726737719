import { Chip, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import logoWave from "../../../../../assets/icon/logo/waveprint.svg";
import React, { useEffect, useState } from "react";
import config from "../../../../../config";
import QRCode from "react-qr-code";
import md5 from "md5";
import { dateFormat } from "../../../../controls";

export const PrintFaktur = React.forwardRef((props, ref) => {
  const { data } = props;
  const { setting } = useSelector((state) => state.data);
  const [printedOn, setPrintedOn] = useState(null);
  const [pengaturan, setPengaturan] = useState({
    nama: "",
    alamat: "",
    no_hp: "",
    logo: "",
  });

  const [dataDetail, setDataDetail] = useState({
    nota: null,
    keterangan: null,
    antrian: 0,
    size: 0,
    additional: 0,
    list: [],
    gambar: null,
  });

  useEffect(() => {
    if (data.nota) {
      setDataDetail({ ...data });
      const date = new Date();
      setPrintedOn(
        `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
      );
    }
  }, [data]);

  useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const no_hp = setting.find((e) => e.rule === "NO_HP");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (no_hp) {
        setPengaturan((old) => ({ ...old, no_hp: no_hp.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <center style={{ paddingBottom: 5 }}>
        {pengaturan.logo && (
          <img
            src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
            alt="Logo"
            width={"40px"}
            height={"auto"}
          />
        )}
        <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
          {pengaturan.nama}
        </Typography>
        <Typography sx={{ fontSize: 9 }}>{pengaturan.alamat}</Typography>
        <Typography sx={{ fontSize: 9 }}>{pengaturan.no_hp}</Typography>
      </center>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
      <table
        width={"100%"}
        style={{
          paddingTop: 10,
          paddingBottom: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <tbody style={{ width: "80%" }}>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              <b>No. Faktur</b>
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              <b>{dataDetail.nota}</b>
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Keterangan
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.keterangan}
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Antrian
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.antrian} Nota
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Order
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.size} Pcs
            </td>
          </tr>
          <tr>
            <td
              width={"45%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              Total Additional
            </td>
            <td
              width={"5%"}
              align={"left"}
              style={{ alignContent: "baseline" }}
            >
              :
            </td>
            <td align={"left"} style={{ alignContent: "baseline" }}>
              {dataDetail.additional} Pcs
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td rowSpan={5}>
              {dataDetail.nota && (
                <QRCode value={md5(dataDetail.nota)} size={100} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <Divider
        sx={{
          "&::before, &::after": {
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: "black",
          },
        }}
      >
        <Chip
          label="Detail Order"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            height: "100%",
            fontSize: "8px",
          }}
        />
      </Divider>
      <table width={"100%"} style={{ paddingTop: 5, paddingBottom: 8 }}>
        <thead>
          <tr style={{ fontWeight: "bold" }}>
            <td align="left" width={"25%"}>
              Nota
            </td>
            <td align="left" width={"35%"}>
              Check in
            </td>
            <td align="left" width={"40%"}>
              Leads / Nama
            </td>
          </tr>
        </thead>
        <tbody>
          {/* {data.length ? ( */}
          {dataDetail.list.map((group, indexGroup) => (
            <tr key={indexGroup}>
              <td align="left">{group.nota}</td>
              <td align="left">{dateFormat(group.check_in)}</td>
              <td align="left">
                {group.nama.length > 15
                  ? `${group.nama.slice(0, 15)}...`
                  : group.nama}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>Printed on : {printedOn}</Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>
      {dataDetail.gambar ? (
        <div style={{ paddingTop: "1rem" }}>
          <img
            src={config.apiUrl + "/storage/group/" + dataDetail.gambar}
            alt="desain"
            style={{ objectFit: "cover", height: "auto", width: "100%" }}
          />
        </div>
      ) : null}
    </div>
  );
});

export default PrintFaktur;
