const checkColor = (props) => {
	if (props === "Cancel" || props === "Belum Lunas") {
		return "#FF4040";
	}
	if (props === "Pending") {
		return "#F87700";
	}
	if (props === "Selesai" || props === "Lunas") {
		return "#39C236";
	}
	if (props === "Baru Masuk") {
		return "#0690DD";
	}
	if (props === "Lunas") {
		return "#0690DD";
	}
	if (props === "Belum Lunas") {
		return "#FF4040";
	}
	return "#1B262C";
};

export default checkColor;
