import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../../components/Layout/Content";
import { SelectionGroup } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import {
  CheckboxInput,
  CustomButton,
  Select,
} from "../../../../components/controls";
import { ActionGrid } from "../../../../components/common";
import { Api, EventHandlers } from "../repository";
import { SET_ROW_SELECTION } from "../domain/manageGroupNotaSlice";

const ModalGroupSelectedRow = ({ antrianList }) => {
  const { role } = useSelector((state) => state.auth);
  const { control, reset, watch, handleSubmit } = useForm();

  const { data: dataGroup } = Api.readGroup();

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    reset({
      group_id: "",
      confirmation: false,
    });
    EventHandlers.handleGroupSelectionGroupModal({
      dispatch,
      modal: false,
    });
  }, [dispatch, reset]);

  const onSuccess = () => {
    handleClose();
    dispatch(SET_ROW_SELECTION({ rowSelection: [] }));
  };

  const { mutate: mutatePindahGroup, isPending } = Api.pindahAntrianMassGroup({
    onSuccess,
  });

  const onSubmit = (values) => {
    const antrianID = antrianList.map((e) => e.id);
    if (role === "penata") {
      mutatePindahGroup({
        body: { antrian_id: antrianID },
        id: values.group_id,
      });
    } else {
      return false;
    }
  };

  const { rowSelectionGroupModal } = useSelector(
    (state) => state.manageGroupNota
  );

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={rowSelectionGroupModal}
      handleClose={handleClose}
      title={"Group Antrian Nota"}
    >
      <SelectionGroup data={antrianList} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems={"center"} marginTop={3}>
          <Grid item xs={12}>
            <Select
              name={"group_id"}
              control={control}
              label={"Group"}
              options={[
                { id: "ungroup", name: "Ungroup" },
                ...(dataGroup
                  ? dataGroup.data.map((e) => ({ id: e.id, name: e.nota }))
                  : []),
              ]}
              rules={{ required: "Group wajib di isi!" }}
              defaultValue={""}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"} fullWidth>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              fullWidth
              disabled={watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalGroupSelectedRow;
