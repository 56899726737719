import config from "../../../../config";
import {
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_MANAGE_GROUP_MODAL,
  SET_PRINT_MODAL,
  SET_ROW_SELECTION_GROUP_MODAL,
} from "../domain/manageGroupNotaSlice";
import { UPDATE_IMAGE } from "../../../imageDetailSlice";

export class EventHandlers {
  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/antrian/${file}`,
        title: title,
      })
    );
  };

  static handleDetailModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handlePrintModal = ({ dispatch, modal }) => {
    dispatch(SET_PRINT_MODAL({ modal }));
  };

  static handleDetailProgress = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handleManageGroupModal = ({ dispatch, modal }) => {
    dispatch(SET_MANAGE_GROUP_MODAL({ modal }));
  };

  static handleGroupSelectionGroupModal = ({ dispatch, modal }) => {
    dispatch(SET_ROW_SELECTION_GROUP_MODAL({ modal }));
  };
}
