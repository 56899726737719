import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CleaningServices as ClearIcon,
  ForwardOutlined as ArrowRight,
} from "@mui/icons-material";
import { SwitchInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { BlankDialog } from "../../../../components/Layout/Content";
import FormTambahAntrian from "./ModalAntrianSimple/FormTambahAntrian";
import FormConfirmation from "./ModalAntrianSimple/FormConfirmation";
import { Config, EventHandlers } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalAntrianSimple = ({
  isLoading,
  formAntrian,
  onSubmit,
  sourceOrder,
  jenisOrder,
  size,
  ekspedisi,
  metodePembayaran,
}) => {
  const [isLoadingClear, setIsLoadingClear] = useState(false);

  const { createSimpleModal, createSimpleModalPage } = useSelector(
    (state) => state.antrianNota
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    EventHandlers.handleCreateSimpleModal({
      dispatch,
      modal: { id: null, open: false, title: null },
      clearErrors: formAntrian.clearErrors,
      reset: formAntrian.reset,
    });
  };

  const { size: Sizes } = formAntrian.getValues();
  const isAtLeastOneActiveSize = () => {
    return Sizes && Sizes.some((size) => size.isActive && size.qty > 0);
  };

  const isAtLeastOneQty = () => {
    const getSize = formAntrian.watch("size");
    const activeSizes = getSize.filter((size) => size.isActive); // Filter size yang aktif
    return activeSizes.every((size) => size.qty > 0); // Periksa setiap size aktif, apakah ada detail_order
  };

  return (
    <BlankDialog
      maxWidth={createSimpleModalPage === 3 ? "sm" : "xl"}
      open={createSimpleModal.open}
      handleClose={handleClose}
      outsideClose={false}
      isLoading={isLoading}
      hideCloseIcon={createSimpleModalPage === 3 && true}
      title={
        <>
          {createSimpleModalPage === 1 ? (
            <>
              <SwitchInput
                name={"antrian.prioritas"}
                control={formAntrian.control}
                label={"Antrian Prioritas"}
                defaultValue={false}
                sx={{
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
              <span style={{ fontSize: "14px" }}>Tambah Antrian</span>
            </>
          ) : createSimpleModalPage === 1 ? (
            <span style={{ fontSize: "14px" }}>Detail Order</span>
          ) : (
            <span style={{ fontSize: "14px" }}>Tambah Antrian</span>
          )}
        </>
      }
      action={
        createSimpleModalPage === 1 ? (
          <Grid
            container
            spacing={2}
            justifyContent={
              createSimpleModalPage === 3 ? "end" : "space-between"
            }
            padding={"1rem"}
          >
            <Grid item xs={3}>
              <CustomButton
                loading={isLoadingClear}
                starticon={
                  !isLoadingClear && <ClearIcon sx={{ rotate: "45deg" }} />
                }
                disabled={isLoading ? true : false}
                onClick={() => {
                  setIsLoadingClear(true);
                  setTimeout(() => {
                    const defaultValues = Config.defaultValue;
                    defaultValues["size"] = size.map((sizeItem) => ({
                      size_id: sizeItem.id,
                      label: sizeItem.name,
                      isActive: false,
                      harga: 0,
                      qty: 0,
                      status: sizeItem.status,
                    }));
                    formAntrian.reset(defaultValues);
                    setIsLoadingClear(false);
                  }, 500);
                }}
                warna={"clear"}
              >
                Clear
              </CustomButton>
            </Grid>
            <Grid item xs={3}>
              <CustomButton
                endicon={<ArrowRight />}
                onClick={() => {
                  EventHandlers.handleCreateSimplePageModal({
                    dispatch,
                    page: 2,
                  });
                }}
                warna={"next"}
                disabled={
                  !formAntrian.formState.isValid ||
                  !isAtLeastOneActiveSize() ||
                  !isAtLeastOneQty()
                }
              >
                Lanjutkan
              </CustomButton>
            </Grid>
          </Grid>
        ) : (
          <ActionGrid
            padding={"1rem"}
            isLoading={isLoading}
            actionStart={
              <CustomButton
                disabled={isLoading ? true : false}
                onClick={() =>
                  EventHandlers.handleCreateSimplePageModal({
                    dispatch,
                    page: 1,
                  })
                }
                warna={"clear"}
                widthbtn={"100%"}
              >
                Batal
              </CustomButton>
            }
            actionEnd={
              <CustomButton
                loading={isLoading}
                disabled={!formAntrian.watch("confirmation")}
                warna={"next"}
                widthbtn={"100%"}
                onClick={onSubmit}
              >
                Simpan
              </CustomButton>
            }
          />
        )
      }
    >
      <form onSubmit={onSubmit}>
        {createSimpleModalPage === 1 ? (
          <FormTambahAntrian
            {...{
              formAntrian,
              size,
              jenisOrder,
              sourceOrder,
              ekspedisi,
            }}
          />
        ) : (
          <FormConfirmation
            {...{
              formAntrian,
              ekspedisi,
              jenisOrder,
              sourceOrder,
              metodePembayaran,
            }}
          />
        )}
      </form>
    </BlankDialog>
  );
};

export default ModalAntrianSimple;
