import {
  Groups as GroupsIcon,
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  AssistWalker as AssistWalkerIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  CollectionsBookmark as CollectionsBookmarkIcon,
  InsertChart as InsertChartIcon,
  Payments as PaymentsIcon,
  ReplayCircleFilled as ReplayCircleFilledIcon,
  Analytics as AnalyticsIcon,
  Settings as SettingsIcon,
  AppRegistration as Manage,
} from "@mui/icons-material";
/*===================================== */

/*
 Root Routes
 List :
    - Data User
    - Antrian
    - Rekap
 */
const root_router = [
  {
    icon: <GroupsIcon />,
    label: "User Account",
    path: "/data_user",
  },
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <AssignmentTurnedInIcon />,
    label: "Checkout",
    path: "/checkout",
  },
  {
    icon: <CollectionsBookmarkIcon />,
    label: "Rekap Antrian",
    path: "/rekap",
  },
  {
    icon: <PaymentsIcon />,
    label: "Riwayat Bayar",
    path: "/cashflow",
  },
  {
    icon: <ReplayCircleFilledIcon />,
    label: "Retur",
    path: "/retur",
  },
  {
    icon: <SettingsIcon />,
    label: "Pengaturan",
    path: "/pengaturan",
  },
];
/*===================================== */
/*
 Customer Service
 List :
    - Dashboard
    - Antrian
    - Checkout
    - Pendapatan
    - Rekap
 */
const customer_service_router = [
  {
    icon: <DashboardIcon />,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <AssignmentTurnedInIcon />,
    label: "Checkout",
    path: "/checkout",
  },
  {
    icon: <CollectionsBookmarkIcon />,
    label: "Rekap Antrian",
    path: "/rekap",
  },
  {
    icon: <InsertChartIcon />,
    label: "Pendapatan",
    path: "/pendapatan",
  },
  {
    icon: <PaymentsIcon />,
    label: "Cash Flow",
    path: "/cashflow",
  },
  {
    icon: <ReplayCircleFilledIcon />,
    label: "Retur",
    path: "/retur",
  },
];
/*===================================== */
/*
 Desainer
 List :
    - Antrian
    - Sedang Dikerjakan
    - Pencapaian
 */
const desainer_router = [
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <AssistWalkerIcon />,
    label: "Dikerjakan",
    path: "/my-project",
  },
  {
    icon: <AnalyticsIcon />,
    label: "Pencapaian",
    path: "/pencapaian",
  },
];
/*===================================== */
/*
 Admin Penata
 List :
    - Antrian
    - Sedang Dikerjakan
    - Pencapaian
 */
const penata_router = [
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <Manage />,
    label: "Manage Group",
    path: "/manage-group",
  },
  {
    icon: <CollectionsBookmarkIcon />,
    label: "Rekap Antrian",
    path: "/rekap",
  },
];
/*===================================== */
/*
 Admin Produksi
 List :
    - Antrian
    - Sedang Dikerjakan
    - Pencapaian
 */
const admin_produksi_router = [
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <AssistWalkerIcon />,
    label: "Dikerjakan",
    path: "/my-project",
  },
  {
    icon: <AnalyticsIcon />,
    label: "Pencapaian",
    path: "/pencapaian",
  },
];
/*===================================== */
/*
 Owner
 List :
    - Dashboard
    - Pendapatan
    - Pencapaian
    - Rekap
    - Pengaturan
 */
const owner_router = [
  {
    icon: <DashboardIcon />,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: <AssignmentIcon />,
    label: "Antrian",
    path: "/antrian",
  },
  {
    icon: <AssignmentTurnedInIcon />,
    label: "Checkout",
    path: "/checkout",
  },
  {
    icon: <CollectionsBookmarkIcon />,
    label: "Rekap Antrian",
    path: "/rekap",
  },
  {
    icon: <InsertChartIcon />,
    label: "Pendapatan",
    path: "/pendapatan",
  },
  {
    icon: <PaymentsIcon />,
    label: "Cash Flow",
    path: "/cashflow",
  },
  {
    icon: <ReplayCircleFilledIcon />,
    label: "Retur",
    path: "/retur",
  },
  // {
  //   icon: <SettingsIcon />,
  //   label: "Pengaturan",
  //   path: "/pengaturan",
  // },
];
export {
  root_router,
  customer_service_router,
  desainer_router,
  admin_produksi_router,
  penata_router,
  owner_router,
};
