import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../../components/Layout/Content";
import { DetailAntrianLayout } from "../../../../components/Layout/Content/Antrian";
import { EventHandlers } from "../repository";

const DetailAntrian = (props) => {
  const { data, isLoading } = props;
  const { detailModal } = useSelector((state) => state.manageGroupNota);
  const dispatch = useDispatch();
  const handleClose = () => {
    EventHandlers.handleDetailModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const previewImage = (gambar) => {
    EventHandlers.handlePreview({
      dispatch,
      file: gambar,
      title: `Detail Gambar Nota : ${gambar}`,
    });
  };

  return (
    <BlankDialog
      open={detailModal.open}
      title={detailModal.title}
      handleClose={handleClose}
      isLoading={isLoading}
      maxWidth={"md"}
    >
      <DetailAntrianLayout
        data={data}
        isLoading={isLoading}
        previewImage={previewImage}
      />
    </BlankDialog>
  );
};

export default DetailAntrian;
