import * as React from "react";
import {
  styled,
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";

const CustomListItem = styled(ListItem)(() => ({
  padding: "0",
}));

const CustomLinkStyle = styled(NavLink)(({ theme }) => ({
  width: "100%",
  padding: "8px 8px 8px 32px",
  display: "flex",
  alignItems: "center",
  textDecoration: "none !important",
  color: "#797979",
  "& .MuiListItemIcon-root": {
    minWidth: "auto",
    marginRight: theme.spacing(2),
    color: "inherit",
    fontSize: 18,
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
  },
  "&.active": {
    backgroundColor: "white",
    color: "#1976d2 !important",
    boxShadow: "0px 2px 5px 0px #00000040",
    borderRadius: "3px",
  },
  "&:hover": {
    color: "#1976d2",
  },
}));

const SideBar = ({ setOpen }) => {
  const { drawerOpen, drawerWidth } = useSelector((state) => state.theme);
  const { menuList } = useSelector((state) => state.auth);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const setSidebar = (props) => {
    if (props) {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "#f1f6f8",
          borderRightColor: "#f1f6f8",
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant={isSmall ? "temporary" : "persistent"}
      anchor="left"
      open={drawerOpen}
      ModalProps={{ onClose: handleClose }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto", marginTop: 2 }}>
        {menuList.length ? (
          <List sx={{ pr: "7px" }}>
            {menuList.map((route, index) => (
              <CustomListItem
                key={route.label}
                disablePadding
                onClick={() => setSidebar(isSmall)}
                button
              >
                <CustomLinkStyle to={route.path}>
                  <ListItemText
                    primary={route.label}
                    sx={{
                      fontSize: "12px",
                      lineHeight: "15px",
                      fontWeight: 700,
                    }}
                  />
                  <ListItemIcon>{route.icon}</ListItemIcon>
                </CustomLinkStyle>
              </CustomListItem>
            ))}
          </List>
        ) : (
          <List>
            <Stack spacing={1}>
              {[...Array(9)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={230}
                  height={44}
                  animation="wave"
                />
              ))}
            </Stack>
          </List>
        )}
      </Box>
    </Drawer>
  );
};

export default SideBar;
