import * as React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RiseLoader } from "react-spinners";

const Initial = () => {
  const { username, role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (username) {
      if (role === "root") {
        navigate("/antrian", { replace: true });
      }
      if (role === "customer_service") {
        navigate("/antrian", { replace: true });
      }
      if (role === "desainer") {
        navigate("/antrian", { replace: true });
      }
      if (role === "admin_produksi") {
        navigate("/antrian", { replace: true });
      }
      if (role === "penata") {
        navigate("/antrian", { replace: true });
      }
      if (role === "owner") {
        navigate("/antrian", { replace: true });
      }
    }
  }, [navigate, username, role]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", backgroundColor: "#fff" }}
    >
      <Grid item xs={3}>
        <RiseLoader color="#1976d2" size={20} />
      </Grid>
    </Grid>
  );
};

export default Initial;
