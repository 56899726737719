import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const Loading = ({ isLoading }) => {
  const { loading } = useSelector((state) => state.theme);
  return (
    <Backdrop
      open={loading || isLoading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 12000 }}
    >
      <CircularProgress />
    </Backdrop>
  );
};
