import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { TotalAntrianCard } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import DetailAntrianPerNota from "./DetailAntrianPerNota";

const PopupTotalAntrian = () => {
  const dispatch = useDispatch();
  const { cardTotalAntrian } = useSelector((state) => state.antrianGroup);
  const handleClose = () => {
    EventHandlers.handleCardTotalAntrian({
      dispatch,
      modal: { id: null, open: false, title: null, data: [] },
    });
  };

  const onDetail = (id) => {
    EventHandlers.handleDetailModal({
      dispatch,
      modal: {
        id: id,
        open: true,
        title: "Detail Antrian",
      },
    });
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={cardTotalAntrian.open}
      handleClose={handleClose}
      title={cardTotalAntrian.title}
    >
      <TotalAntrianCard data={cardTotalAntrian.data} onDetail={onDetail} />
      <DetailAntrianPerNota />
    </BlankDialog>
  );
};

export default PopupTotalAntrian;
