import { matchIsValidColor, MuiColorInput } from "mui-color-input";
import { Controller } from "react-hook-form";

const ColorInput = ({
  name,
  control,
  defaultValue,
  label,
  shrinklabel,
  isDisabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ validate: matchIsValidColor }}
      render={({ field, fieldState }) => (
        <MuiColorInput
          {...field}
          format="hex"
          helperText={fieldState.error ? "Color is invalid" : ""}
          error={fieldState.error}
          label={label}
          fullWidth
          sx={{
            "& .MuiInputBase-root": {
              color: "#797979",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 0px #00000040 inset",
              "& .MuiOutlinedInput-input": {
                padding: "8.5px 14px",
              },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              ...(isDisabled && {
                color: "rgba(0, 0, 0, 0.38)",
                backgroundColor: "#ECECEC",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
              }),
            },
            "& .MuiOutlinedInput-notchedOutline": {
              marginTop: "5px",
            },
            "& .MuiInputLabel-shrink": {
              top: "-5px",
              ...(isDisabled && {
                pointerEvents: "none",
                color: "rgba(0, 0, 0, 0.38)",
              }),
            },
            "& legend": {
              display: "none",
            },
            "& .Mui-focused + .MuiOutlinedInput-root": {
              color: "#797979 !important",
              "& .MuiInputLabel-shrink": {
                color: "#1976d2",
              },
            },
            ...(isDisabled && {
              cursor: "default !important",
              pointerEvents: "none",
              "& .MuiInputAdornment-root": {
                opacity: 0.38,
              },
            }),
          }}
          InputLabelProps={{
            shrink: shrinklabel,
          }}
        />
      )}
    />
  );
};

export default ColorInput;
