import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ColorLens as DesainerIcon,
  LocalFireDepartment as ProduksiIcon,
  Settings as GearIcon,
  FolderCopy as GroupIcon,
} from "@mui/icons-material";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/antrianNotaSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardAntrian from "./TabelAntrian/FooterCardAntrian";
import ActionButton from "./TabelAntrian/ActionButton";

const TabelAntrian = ({
  data,
  isLoading,
  jenisOrder,
  sourceOrder,
  statusList,
}) => {
  const dispatch = useDispatch();

  const statusDesainer = statusList.find((status) => status.kode === "S01");

  const statusProduksi = statusList.find((status) => status.kode === "S03");

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.antrianNota);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const statusOptions = Array.from(
    new Set(data.map((item) => item.status.status))
  );

  const jenisOrderOptions = Array.from(
    new Set(jenisOrder.filter((jenis) => jenis.status).map((item) => item.name))
  );

  const sourceOrderOptions = Array.from(
    new Set(
      sourceOrder.filter((jenis) => jenis.status).map((item) => item.name)
    )
  );

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "jenis_order") {
      return {
        ...e,
        valueOptions: jenisOrderOptions,
      };
    }
    if (e.field === "source_order") {
      return {
        ...e,
        valueOptions: sourceOrderOptions,
      };
    }
    if (e.field === "preview") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionPreview({
              role,
              params,
              dispatch,
            });
          }
          return [];
        },
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            const statusDeleteAntrian = setting.find(
              (item) => item.rule === "DELETE_ANTRIAN"
            );
            return ActionButton.getActionOpsi({
              role,
              params,
              dispatch,
              statusDeleteAntrian: statusDeleteAntrian.value === "false",
            });
          }
          return [];
        },
      };
    }
    return e;
  });

  if (role === "root" || role === "customer_service") {
    const indexToInsertAfter = columns.findIndex((e) => e.field === "actions");

    const statusEditAntrian = setting.find(
      (item) => item.rule === "EDIT_ANTRIAN"
    );

    const statusEditSize = setting.find((item) => item.rule === "EDIT_SIZE");

    const statusSkipDesainer = setting.find(
      (item) => item.rule === "SKIP_DESAINER"
    );

    columns.splice(indexToInsertAfter, 0, {
      headerName: "Edit",
      disableClickEventBubbling: true,
      field: "edit",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      width: 60,
      getActions: (params) => {
        if (typeof params.id === "number") {
          return ActionButton.getActionEdit({
            params,
            dispatch,
            statusSkipDesainer:
              role !== "root"
                ? statusSkipDesainer.value === "false"
                : false || params.row.status_kode !== statusDesainer?.kode,
            statusEditAntrian:
              role !== "root" ? statusEditAntrian.value === "false" : false,
            statusEditSize:
              role !== "root" ? statusEditSize.value === "false" : false,
          });
        }
        return [];
      },
    });
  }

  if (role === "root") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "status_pembayaran"
    );

    columns.splice(indexToInsertAfter + 1, 0, {
      field: "actionProgress",
      minWidth: 150,
      hideable: false,
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: true,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: false,
      renderHeader: () => <GearIcon sx={{ color: "#797979" }} />,
      renderCell: (params) => {
        if (typeof params.id === "number") {
          return (
            <Button
              size="small"
              startIcon={<GearIcon sx={{ color: "#B4B4B4" }} />}
              variant="outlined"
              color="primary"
              sx={{
                color: "#B4B4B4",
                borderColor: "#B4B4B4",
                borderWidth: "2px",
                borderRadius: "15px",
                padding: "2px 15px",
                fontSize: "10px",
                "&:hover": {
                  borderWidth: "2px",
                  color: "#000",
                  borderColor: "#000",
                  "& .MuiSvgIcon-root": { color: "#000" },
                },
              }}
              onClick={() => {
                EventHandlers.handleProgressModal({
                  dispatch,
                  modal: {
                    id: params.id,
                    open: true,
                    title: "Edit Progress !",
                  },
                });
              }}
            >
              Progress
            </Button>
          );
        }
        return [];
      },
    });
  }

  if (role === "admin_produksi" || role === "desainer") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "status_pembayaran"
    );

    if (indexToInsertAfter !== -1) {
      columns.splice(indexToInsertAfter + 1, 0, {
        field: "aksi_proses",
        minWidth: 120,
        headerName: "Aksi Proses",
        hideable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "center",
        renderHeader: () =>
          role === "admin_produksi" ? <ProduksiIcon /> : <DesainerIcon />,
        renderCell: (params) => {
          if (typeof params.id === "number") {
            if (role === "admin_produksi") {
              return (
                <>
                  <Button
                    size="small"
                    startIcon={
                      params.row.groupped ? (
                        <GroupIcon sx={{ color: "#d86523" }} />
                      ) : (
                        <ProduksiIcon
                          sx={{
                            color: "white",
                            backgroundColor: "#D86F23",
                            borderRadius: 50,
                            padding: "2px",
                          }}
                        />
                      )
                    }
                    variant="outlined"
                    color="primary"
                    sx={{ borderRadius: 15, textTransform: "capitalize" }}
                    onClick={() => {
                      EventHandlers.handleAmbilAntrianModal({
                        dispatch,
                        modal: {
                          id: params.id,
                          open: true,
                          title: "Ambil Antrian",
                        },
                      });
                    }}
                    disabled={
                      params.row.produksi
                        ? true
                        : false ||
                          params.row.status_kode !== statusProduksi?.kode ||
                          params.row.groupped
                    }
                  >
                    {params.row.groupped ? "Groupped" : "Produksi"}
                  </Button>
                </>
              );
            }

            return (
              <Button
                size="small"
                startIcon={<DesainerIcon />}
                variant="outlined"
                color="primary"
                sx={{ borderRadius: 15, textTransform: "capitalize" }}
                onClick={() => {
                  EventHandlers.handleAmbilAntrianModal({
                    dispatch,
                    modal: {
                      id: params.id,
                      open: true,
                      title: "Ambil Antrian",
                    },
                  });
                }}
                disabled={
                  params.row.desainer
                    ? true
                    : false || params.row.status_kode !== statusDesainer?.kode
                }
              >
                Desain
              </Button>
            );
          }

          return [];
        },
      });
    }
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
      footerComponent={({ finalData }) => {
        return <FooterCardAntrian data={finalData} isLoading={isLoading} />;
      }}
    />
  );
};

TabelAntrian.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelAntrian;
