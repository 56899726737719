import React from "react";
import { Stack } from "@mui/material";
import Layout from "../layout/Layout";
import CardGroupPencapaian from "../features/dashboard/CardGroupPencapaian/CardGroupPencapaian";
// import CardGrafikJenisOrder from "../features/dashboard/CardGrafikJenisOrder/CardGrafikJenisOrder";
// import CardGrafikSourceOrder from "../features/dashboard/CardGrafikSourceOrder/CardGrafikSourceOrder";
import CardTabelPembayaran from "../features/dashboard/TabelGrafikPembayaran/CardTabelPembayaran";
import CardTabelReject from "../features/dashboard/TabelGrafikReject/CardTabelReject";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { setting } = useSelector((state) => state.data);
  // CHANGE TITLE
  React.useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Dashboard`;
      } else {
        document.title = `Dashboard`;
      }
    } else {
      document.title = "Dashboard";
    }
  }, [setting]);
  return (
    <Layout>
      <Stack spacing={2}>
        <CardGroupPencapaian />
        {/* <CardGrafikJenisOrder /> */}
        {/* <CardGrafikSourceOrder /> */}
        <CardTabelPembayaran />
        <CardTabelReject />
      </Stack>
    </Layout>
  );
};

export default Dashboard;
