import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { RESET_USER } from "../features/authSlice";

const ToLogin = (props) => {
  const dispatch = useDispatch();

  const { location } = props;

  useEffect(() => {
    dispatch(RESET_USER());
  });
  return <Navigate to="/login" state={{ from: location }} replace />;
};

const RequireAuth = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return token ? <Outlet /> : <ToLogin location={location} />;
};

export default RequireAuth;
